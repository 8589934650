import React from "react";
import { graphql } from "gatsby";
import { Trans } from "gatsby-plugin-react-i18next";
import Image from "gatsby-image";

import Layout from "../components/layout/layout";
import SEO from "../components/seo/seo";

import svg94 from "../images/assets/94-gray.svg";

export const pageQuery = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    maria: file(relativePath: { eq: "about/maria-2021.jpg" }) {
      ...fluidImage
    }
  }
`;

const About = ({ data }) => {
  const description =
    "María Estarán Silván es la diseñadora gráfica detrás de Estudio 94";

  const associates = [
    {
      name: "Enric Fortin",
      jobTitle: "Ingeniero de software",
      link: null,
      description:
        "Soy ingeniero de software, especializado en infraestructura en la nube, desarrollo de APIs en Python y .NET, y desarrollo de aplicaciones web con tecnologías SPA y Jamstack.",
    },
    {
      name: "Ana Silván",
      jobTitle: "Diseñadora de interiores",
      link: ["https://www.anasilvan.com", "anasilvan.com"],
      description:
        "Especialista en diseño de interiores, proyectos de decoración y organización de eventos, tanto para tu hogar como para tu negocio. También tengo una tienda online de decoración.",
    },
  ];

  return (
    <Layout pagename="about">
      <SEO title="María Estarán" description={description} />
      <div className="page_content">
        <h3>
          <Trans>¿Quién es Estudio 94?</Trans>
        </h3>
        <p className="about__intro">
          <Trans>
            Estudio 94 es un estudio de diseño integral, que nace con el
            objetivo de proveer servicios de diseño gráfico, web y márketing a
            clientes particulares o corporativos, a precios accesibles para
            todos. Somos un equipo dinámico y siempre tenemos ganas de afrontar
            nuevos retos.
          </Trans>
        </p>
        <div className="about__people">
          <div className="about__boss">
            <Image
              className="about__image"
              fluid={data.maria.childImageSharp.fluid}
            />
            <h4>María Estarán</h4>
            <p>
              <Trans>
                Soy la diseñadora gráfica que hay detrás de Estudio 94. En mis
                años de experiencia me he especializado en diseño editorial,
                packaging y marketing digital. He pasado los últimos años de mi
                carrera profesional en Reino Unido, trabajando para un holding
                empresarial como diseñadora principal, y ahora mi objetivo es
                aportar a los clientes de Estudio 94 todos los conocimientos que
                he adquirido.
              </Trans>
            </p>
            <p className="quote">
              <Trans>
                Como diseñadora, mi trabajo es solucionar el problema de
                equilibrio entre forma y función, con un poco de imaginación y
                mucho cuidado al detalle, siempre se encuentra una solución.
              </Trans>
            </p>
          </div>
          <div className="about__associates">
            <div className="about__image about__image--associates">
              <img src={svg94} />
            </div>
            <h4>
              <Trans>Equipo</Trans>
            </h4>
            {associates.map(({ name, jobTitle, link, description }) => (
              <div className="about__associate" key={name}>
                {link && (
                  <h5>
                    {name} |{" "}
                    <a className="about__associate_link" href={link[0]}>
                      {link[1]}
                    </a>
                  </h5>
                )}
                {!link && <h5>{name}</h5>}
                <span className="about__associate_job_title">
                  <Trans>{jobTitle}</Trans>
                </span>
                <p className="about__associate_description">
                  <Trans>{description}</Trans>
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default About;
